body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nice-border {
  padding: 32px;
  border-radius: 32px;
  box-shadow: 0px 0px 12px 6px #eee;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 1100px) {
  .nice-border {
    padding: 16px;
    border-radius: 32px;
    box-shadow: 0px 0px 12px 6px #eee;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.button {
  max-height: 3em;
  font-size: 1.1em;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border-radius: 5px 5px 5px 5px;
  background: rgb(0, 0, 0);
  padding: 0.35em 0.75em;
  color: rgb(255, 255, 255);
  border: none;
  display: inline-block;
  cursor: pointer;
}

/* RESUME */

.pdf {
  display: block;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 900px;
}

.download-button {
  margin: 32px;
}

.resume-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  width: 100vw;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.resume-li {
  display: block;
  color: blue;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  border-radius: 32px;
  margin: 8px;
}

/* Change the link color to #111 (black) on hover */
.resume-li:hover {
  background-color: black;
  color: white !important;
}

.resume-top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.resume-item {
  width: 100%;
}

.resume-item-image {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.resume-item-image-left {
  width: 75%;
}

.resume-item-image-right {
  width: 20%;
}

.resume-icon {
  width: 128px;
  height: auto;
}

.resume-item-text {
  width: 100%;
  text-align: left;
}

.resume-item-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 20%;
}

.active-resume-tab {
  border: 1px dotted black;
}

.resume-image {
  width: 100%;
  max-height: 20vh;
  object-fit: cover;
  border-radius: 32px;
}

.sub-item-border {
  border-top: 1px solid #c7c7c7;
  padding-top: 8px;
}

@media (max-width: 1100px) {
  .resume-top {
    display: flex;
    flex-direction: column;
  }
  .resume-item {
    display: flex;
    flex-direction: column;
  }
  .resume-item-image {
    display: flex;
    flex-direction: column;
  }
  .resume-image {
    width: 100%;
    object-fit: cover;
    border-radius: 32px;
  }
  .resume-item-image-left {
    width: 100%;
  }
  .resume-item-image-right {
    width: 100%;
  }
  .resume-icon {
    width: 64px;
    height: auto;
  }
}

/* PROJECTS */

.title {
  text-align: center;
  margin-top: 32px;
}
.project-image {
  height: 40vh;
  width: 100%;
  object-fit: cover;
}

.project-grid {
  display: flex;
  flex-wrap: wrap;
}

.project-item {
  width: 50%;
  padding: 10px;
}

@media (max-width: 1100px) {
  .project-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .project-item {
    width: 100%;
  }
}

/* NAVBAR */

.nav-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  background-image: linear-gradient(to right, #cc66ff, #6666ff);
  font-size: 18px;
  font-weight: 700;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.nav-li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.nav-li a:hover {
  background-color: #cc99ff;
  color: white !important;
}

@media (max-width: 1100px) {
  .nav-ul {
    display: flex;
    flex-wrap: wrap;
  }
}

/* HOME */

p {
  text-align: center;
}

.paragraph {
  width: 700px;
  margin: auto;
  text-align: center;
}
.header {
  padding: 32px;
  text-align: center;
}

.list-item {
  padding: 32px;
  border-radius: 32px;
  box-shadow: 0px 0px 12px 6px #eee;
  margin: 16px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text {
  margin-bottom: 32px;
}
.image {
  width: 400px;
}
.small-image {
  width: 200px;
}

.home-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 700px) {
  .columns {
    display: block;
  }
  .list-item {
    width: 80%;
    margin: 16px auto;
  }
  .image {
    width: 100%;
  }
  .paragraph {
    width: 80%;
    margin: auto;
    text-align: center;
  }
}

/* EDUCATION */

.education-item {
  padding: 32px;
  border-radius: 32px;
  box-shadow: 0px 0px 12px 6px #eee;
  margin: 16px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* CONTACT */

.contact-item {
  padding: 32px;
  border-radius: 32px;
  box-shadow: 0px 0px 12px 6px #eee;
  margin: 16px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 700px) {
  .columns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

/* WEBAPPS */

.webapp-image {
  height: 20vh;
  width: 100%;
  object-fit: cover;
}

.webapp-grid {
  display: flex;
  flex-wrap: wrap;
}

.webapp-item {
  width: 50%;
  padding: 10px;
}

@media (max-width: 1100px) {
  .webapp-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .webapp-item {
    width: 100%;
  }
}

.blog-item {
  width: 50vw;
}

@media (max-width: 1100px) {
  .blog-item {
    width: 100%;
  }
}

.blog-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.error-cat {
  width: 50vw;
}

@media (max-width: 1100px) {
  .error-cat {
    width: 100%;
  }
}
